// getting data user on localstorage
import crypto from 'crypto';
import moment from 'moment-timezone';
import { notification, Tag, Typography } from 'antd';
import axios from 'axios';
import qs from 'qs';
import * as EmailValidator from 'email-validator';
import { isMoment } from 'moment';
import _ from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';
import React from 'react';
import PDFJS from 'pdfjs-dist/webpack';
import { ReactComponent as ExclamationIcon } from '@assets/icon/svg/exclamation-circle.svg';
import { store } from '../redux/store';
import PdfThumbnail from '../assets/img/pdf.svg';
import DefaultThumbnail from '../assets/img/globe.png';
import { ortuProfile } from '../views/Auth/EditProfileTK/formOptions';
import { BASE_PROXY } from '../constants/initHttp';

export const multipleArrayToSingle = (array) => {
  const newArray = [];
  array.forEach((item) => {
    newArray.push(...item);
  });
  return newArray;
};
export const isUserLoggedIn = () => localStorage.getItem('userData');
export const getUserData = () => JSON.parse(localStorage.getItem('userData'));
export const getUserProfile = () => JSON.parse(JSON.parse(localStorage.getItem('persist:root')).Auth).profile;
// eslint-disable-next-line no-confusing-arrow
export const getUserToken = () =>
  localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).data.token : null;
export const getGoogleToken = () => localStorage.getItem('acc');

export const getRouterForLoggedInUser = () => {
  if (localStorage.getItem('userData')) {
    return '/';
  }

  return '/login';
};

export const objectString = (data, convert) => {
  if (!convert) {
    const newdata = [];
    for (const key in data) {
      if (typeof data[key] === 'object') {
        for (const kd in data[key]) {
          if (typeof data[key][kd] === 'object') {
            for (const kdd in data[key][kd]) {
              newdata.push(
                `${encodeURIComponent(key)}[${encodeURIComponent(kd)}]` +
                  `[${encodeURIComponent(kdd)}]=${encodeURIComponent(data[key][kd][kdd])}`
              );
            }
          } else {
            newdata.push(`${encodeURIComponent(key)}[${encodeURIComponent(kd)}]=${encodeURIComponent(data[key][kd])}`);
          }
        }
      } else {
        newdata.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
      }
    }
    return `?${newdata.join('&')}`;
  }
  if (data) {
    data = data.replace('?', '');
    if (data) {
      data = data.split('&');
      const newdata = [];
      for (const key in data) {
        const dk = data[key].split('=');
        const dkk = [];
        dkk[decodeURIComponent(dk[0])] = decodeURIComponent(dk[1]);
        newdata.push(dkk);
      }

      return newdata;
    }
    return false;
  }
};

// const isEmptyObj = (obj) => {
//   for (const prop in obj) {
//     if (obj.hasOwnProperty(prop)) {
//       return false;
//     }
//   }

//   return JSON.stringify(obj) === JSON.stringify({});
// };

export const MakeQuerablePromise = (promise) => {
  // Don't modify any promise that has been already modified.
  if (promise.isResolved) return promise;

  // Set initial state
  let isPending = true;
  let isRejected = false;
  let isFulfilled = false;

  // Observe the promise, saving the fulfillment in a closure scope.
  const result = promise.then(
    (v) => {
      isFulfilled = true;
      isPending = false;
      return v;
    },
    (e) => {
      isRejected = true;
      isPending = false;
      throw e;
    }
  );

  result.isFulfilled = function () {
    return isFulfilled;
  };
  result.isPending = function () {
    return isPending;
  };
  result.isRejected = function () {
    return isRejected;
  };
  return result;
};

export const notificationOverride = ({ title, description = null, type }) => {
  notification.destroy();
  if (type === 'success') {
    notification.info({
      message: (
        <Typography.Text
          strong
          style={{
            fontSize: 16,
            color: '#1F262C',
          }}
        >
          {title}
        </Typography.Text>
      ),
      description: (
        <Typography.Text
          style={{
            fontSize: 14,
            color: '#818589',
          }}
        >
          {description}
        </Typography.Text>
      ),
      style: {
        borderTop: '2px solid #268E6C ',
        backgroundColor: '#F0F7F5',
      },
      icon: (
        <CheckCircleFilled
          style={{
            color: '#268E6C',
          }}
        />
      ),
    });
  } else if (type === 'warning') {
    notification.warn({
      message: title,
      description,
      type,
    });
  } else if (type === 'warning-check') {
    notification.warn({
      message: (
        <Typography.Text
          strong
          style={{
            fontSize: 16,
            color: '#1F262C',
          }}
        >
          {title}
        </Typography.Text>
      ),
      description: (
        <Typography.Text
          style={{
            fontSize: 14,
            color: '#818589',
          }}
        >
          {description}
        </Typography.Text>
      ),
      style: {
        borderTop: '2px solid #C98600 ',
        backgroundColor: '#F0F7F5',
      },
      icon: (
        <CheckCircleFilled
          style={{
            color: '#C98600',
          }}
        />
      ),
    });
  } else {
    notification.error({
      message: (
        <Typography.Text
          strong
          style={{
            fontSize: 16,
            color: '#1F262C',
          }}
        >
          {title}
        </Typography.Text>
      ),
      description: (
        <Typography.Text
          style={{
            fontSize: 14,
            color: '#818589',
          }}
        >
          {description}
        </Typography.Text>
      ),
      type,
      style: {
        borderTop: '2px solid #E63A34',
        backgroundColor: '#FCF3F2',
      },
      icon: <ExclamationIcon />,
    });
  }
};
export const defineStatus = (_start_date, _end_date, need_node = false) => {
  const current = null;
  const start_date = null;
  const end_date = null;

  if (current < start_date) {
    if (need_node) {
      return (
        <>
          <Tag color="blue">Telah Terbit</Tag>
        </>
      );
    }
    return 'Telah Terbit';
  }
  if (current >= start_date && current <= end_date) {
    if (need_node) {
      return (
        <>
          <Tag color="green">Sedang Berlangsung</Tag>
        </>
      );
    }
    return 'Sedang Berlangsung';
  }
  if (need_node) {
    return (
      <>
        <Tag color="red">Telah Berakhir</Tag>
      </>
    );
  }
  return 'Telah Berakhir';
};
export const AxiosInject = (baseUrl = null, type = true, token = null, timeout = 30000) => {
  const instance = axios.create();
  if (baseUrl) {
    instance.defaults.baseURL = baseUrl;
    instance.defaults.timeout = timeout;
  }
  instance.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `${type} ${token}`; // eslint-disable-line no-param-reassign
      }
      if (token) {
        const { method } = config;
        let _body = '';
        let _token = '';
        let _url = config.url;
        if (method === 'post' || method === 'put') {
          const _data = config.data ?? '';
          _body = window.btoa(JSON.stringify(_data));
        }
        const host = _url.split('/')[2];
        _url = _url.replace(`https://${host}`, '');
        _token = config.headers.Authorization;
        config.headers.Timestamp = moment().toISOString();
        let pathName = '';
        config.params &&
          Object.keys(config.params).map((_key, index) => {
            if (config.params[_key] !== null) {
              pathName += `${_key}=${config.params[_key] ?? ''}`;
              if (Object.keys(config.params).length - 1 !== index) {
                pathName += '&';
              }
            }
            return _key;
          });
        if (pathName !== '') {
          _url = `${_url}?${pathName.replace(' ', '+')}`;
        }
        // console.log('url', _url);
        config.headers.Signature = generateSignature(
          _url,
          _token.replace(' ', ''),
          config.method.toUpperCase(),
          config.headers.Timestamp,
          _body
        );
        delete config.headers.Signature;
        delete config.headers.Timestamp;
      }
      return config;
    },
    // eslint-disable-next-line no-undef
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        const _message = error?.response?.data?.message ?? error?.response?.data?.data ?? error?.response?.data?.error;
        if (_message === 'invalid authorization') {
          // notificationOverride({
          //   title: 'Permintaan Ditolak',
          //   description: 'Sesi login Anda telah berakhir. Silakan login kembali untuk melanjutkan.',
          //   type: 'warning',
          // });
        } else if (['unauthorized device', 'UNAUTHORIZED DEVICE'].includes(_message)) {
          notificationOverride({
            title: 'Perangkat Baru Telah Terdaftar',
            description:
              'Secara otomatis akun Anda dikeluarkan dari situs. Silahkan lakukan login kembali untuk mengakses Siswamedia.',
            type: 'warning-check',
          });
        }
        store.dispatch({ type: 'LOGOUT', value: true });
      }
      // hi
      return Promise.reject(error);
    }
  );

  return instance;
};

export function disabledDate(current, custom) {
  if (custom) {
    return (
      current &&
      current <
        moment(custom, 'YYYY-MM-DD').set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
    );
  }
  // Can not select days before today and today
  return current && current < moment().subtract(1, 'days').endOf('day');
}

export const parseQs = (qsRaw) =>
  qs.parse(qsRaw, {
    ignoreQueryPrefix: true,
  });

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export function isSameDay(obj) {
  const now = moment();
  if (isMoment(obj)) {
    return now.isSame(obj, 'day');
  }
  return false;
}

export function isSameHour(obj, compared = null) {
  const now = moment();
  if (isMoment(obj) && !isMoment(compared)) {
    return now.isSame(obj, 'hour');
  }
  if (isMoment(obj) && isMoment(compared)) {
    return compared.isSame(obj, 'hour');
  }
  return false;
}

export function disabledTime(current, custom) {
  const sameDay = current?.isSame(new Date(), 'day');
  if (custom) {
    const sameDayCustom = moment(current)?.isSame(moment(custom), 'day');
    const sameDayCustomHours = moment(current)?.isSame(moment(custom), 'hour');
    if (sameDayCustom) {
      return {
        disabledHours: () => range(0, moment(custom).format('HH')),
        disabledMinutes: () => (sameDayCustomHours ? range(0, moment(custom).format('mm')) : []),
        disabledSeconds: () => range(0, moment(custom).format('ss')),
      };
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };
  }
  const sameHours = isSameHour(current);
  if (sameDay) {
    return {
      disabledHours: () => range(0, moment().format('HH')),
      disabledMinutes: () => (sameHours ? range(0, moment().format('mm')) : null),
      disabledSeconds: () => range(0, moment().format('ss')),
    };
  }

  return {
    disabledHours: () => [],
    disabledMinutes: () => [],
    disabledSeconds: () => [],
  };
}

export const makeId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const reportSentry = (err, code = null) => {
  console.log('err', err);
  console.log('code', code);
  return true;
};

export const isValidEmail = (email) => {
  if (!email) return true;

  return EmailValidator.validate(email);
};

export const romanize = (num) => {
  if (isNaN(num)) return NaN;
  const digits = String(+num).split('');
  const key = [
    '',
    'C',
    'CC',
    'CCC',
    'CD',
    'D',
    'DC',
    'DCC',
    'DCCC',
    'CM',
    '',
    'X',
    'XX',
    'XXX',
    'XL',
    'L',
    'LX',
    'LXX',
    'LXXX',
    'XC',
    '',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
  ];
  let roman = '';
  let i = 3;
  // eslint-disable-next-line no-plusplus
  while (i--) roman = (key[+digits.pop() + i * 10] || '') + roman;
  return Array(+digits.join('') + 1).join('M') + roman;
};

export const htmlEntities = (str, encode = true) => {
  if (encode) {
    str = str.replace(/[\u00A0-\u9999<>\\&]/gim, (i) => `&#${i.charCodeAt(0)};`);
  } else if (str && typeof str === 'string') {
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
  }

  return str;
};

export const hexToRgbA = (hex) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},0.5)`;
  }
  throw new Error('Bad Hex');
};

export const getUjianFormWidth = (xxl, xl, lg, md, sm, xs) => {
  if (lg || xl || xxl) return '60%';
  if (md) return '80%';
  if (xs || sm) return '100%';
};
export const MULTIPLE_CHOICES = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

export const extractQuestionLabel = (options = [], value) => {
  let label = '-';
  options.forEach((item, i) => {
    if (item.value === value) {
      label = MULTIPLE_CHOICES[i] || 'A';
    }
  });
  return label;
};

export const getRangeDateMoment = (start, end) => {
  const range = [];
  let current = moment(start);
  const endDate = moment(end);
  while (current <= endDate) {
    range.push({
      day: current.format('dddd'),
      obj: current.format('YYYY-MM-DD'),
    });
    current = current.add(1, 'days');
  }
  return range;
};

export const getThumbnail = (url) => {
  if (!url) return null;
  // check is youtube url with regex
  if (/^(http|https):\/\/(www\.)?(youtube\.com|youtu\.?be)\/.+/.test(url)) {
    if (url.split('=').length > 1) {
      const id = url.split('=')[1];
      return `https://img.youtube.com/vi/${id}/0.jpg`;
    }
    return DefaultThumbnail;
  }
  if (/^(http|https):\/\/(www\.)?.+\.(jpg|jpeg|gif|png)$/.test(url)) {
    return url;
  }
  if (/^(http|https):\/\/(www\.)?.+\.(pdf)$/.test(url)) {
    return PdfThumbnail;
  }
  return DefaultThumbnail;
};

export const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const getPaginatedItems = (items, page, pageSize) => {
  const pg = page || 1;
  const pgSize = pageSize || 100;
  const offset = (pg - 1) * pgSize;
  const pagedItems = _.drop(items, offset).slice(0, pgSize);
  return {
    current: pg,
    pageSize: pgSize,
    total: items.length,
    total_pages: Math.ceil(items.length / pgSize),
    data: pagedItems,
  };
};

export const createImage = (url) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  // eslint-disable-next-line no-undef
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, 'image/jpeg');
  });
}
export const randomColor = () => Math.floor(Math.random() * 16777215).toString(16);

export const searchLike = (str, search) => {
  if (!search) return true;
  return str.toLowerCase().includes(search.toLowerCase());
};

export const removeHTMLTags = (values) => {
  const regex = /(<([^>]+)>)/gi;
  return values.replace(regex, '');
};

export const renderKomponen = (detailTugas) => {
  if (detailTugas?.curriculum_component_description_object?.length) {
    const idx = detailTugas?.curriculum_component_description_object?.findIndex((i) => {
      const sc = i.sub_components.map((isc) => isc.name);
      if (i.name === detailTugas?.curriculum_component_description) {
        return true;
      }
      if (i.sub_components.length > 0 && sc.includes(detailTugas?.curriculum_component_description)) {
        return true;
      }
      return false;
    });
    if (idx < 0) {
      return {
        komponen: null,
        sub_component: null,
      };
    }
    const subIdx = detailTugas?.curriculum_component_description_object[idx].sub_components.findIndex(
      (i) => i.name === detailTugas?.curriculum_component_description
    );

    return {
      komponen: detailTugas?.curriculum_component_description_object[idx].name,
      sub_component:
        subIdx < 0 ? null : detailTugas?.curriculum_component_description_object[idx].sub_components[subIdx].name,
    };
  }
  return {
    komponen: null,
    sub_component: null,
  };
};

export const safeScore = (newRange, existingRange) =>
  existingRange.max_score < newRange.min_score || newRange.max_score < existingRange.min_score;

export const limitArray = (arr, limit) => {
  if (arr.length > limit) {
    return arr.slice(0, limit);
  }
  return arr;
};

export const getPdfImage = (blob) =>
  PDFJS.getDocument(blob).promise.then((pdf) =>
    pdf.getPage(1).then((page) => {
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderContext = {
        canvasContext: context,
        viewport,
      };
      return page.render(renderContext).promise.then(() => canvas.toDataURL('image/jpeg'));
    })
  );

export const generateSignature = (path, token, method, timestamp, body) => {
  let pathWithSlash = path[0] === '/' ? path : `/${path}`;
  pathWithSlash = pathWithSlash.replace(/\s/g, '+');
  if (pathWithSlash.includes('objects?path=')) {
    // get path= value
    const pathValue = pathWithSlash.split('path=')[1];
    const firstSplit = pathValue.split('&')[0];
    // check if firstSplit has "/" on last
    if (firstSplit[firstSplit.length - 1] === '/') {
      // firstSplit replace "/" to "%2F"
      const secondSplit = firstSplit.replace(/\//g, '%2F');
      // replace firstSplit with secondSplit
      pathWithSlash = pathWithSlash.replace(firstSplit, secondSplit);
    }
    // pathWithSlash = pathWithSlash.replace(/\s/g, ' ');
    // pathWithSlash = pathWithSlash.replace('+', ' ');
    // console.log('pathWithSlash', pathWithSlash);
  }
  if (pathWithSlash.includes('objects/generate-presign-url')) {
    // console.log('isPresign', pathWithSlash);
    // + to space
    pathWithSlash = pathWithSlash.replace(/\+/g, ' ');
  }
  const _formatter = `path=${pathWithSlash}|method=${method}|token=${token}|timestamp=${timestamp}|body=${body}`;
  // console.log('payload', _formatter);
  const hmac = crypto.createHmac('sha256', process.env.REACT_APP_SECRET_KEY).update(_formatter, 'utf8', 'base64');
  return hmac.digest('base64');
};

export const rupiahFormat = (number) => addComma(number, '.');
export const addComma = (value, replaceTo = ',') => value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, replaceTo);

export const renderNisn = (val) => {
  if (val?.nis && val?.nisn) return `${val?.nis} / ${val?.nisn}`;
  if (val?.nis && !val?.nisn) return val?.nis;
  if (!val?.nis && val?.nisn) return val?.nisn;
  return '-';
};

export const uniqueArray = (arr) => [...new Set(arr)];

export const handleSameAlamat = (val, type, currentValue) => {
  const newVal = { ...currentValue };
  ortuProfile.forEach((item) => {
    if (newVal[`same_${item.value}_address`]) {
      newVal[`${item.value}_${type}`] = val;
    }
  });
  newVal[`${type}`] = val;
  return newVal;
};

export const containString = (str, search) => {
  if (!search || !str) {
    return true;
  }
  return str.toLowerCase().includes(search.toLowerCase());
};

export const directLinkWithoutProxy = (fileId) =>
  encodeURIComponent(`https://drive.google.com/uc?export=view&id=${fileId}`);
export const directLinkGoogle = (fileId) => `${BASE_PROXY}?url=${directLinkWithoutProxy(fileId)}`;
export const proxyLink = (url) => `${BASE_PROXY}?url=${encodeURIComponent(url)}`;
export const directLinkGoogleWOProxy = (fileId) => `https://drive.google.com/uc?export=view&id=${fileId}`;

export const AES_KEY = 'goinitsecret32bitsupersecret';
export const AES_FRONT_KEY = 'AFd6N3v1ebLw711zxpZjxZ7iq4fYpNYa';
export const AES_FRONT_IV = 'MesA7nqIVa23b167';
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
